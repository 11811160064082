.app__faq {
  margin: 3rem 3rem;
  font-family: var(--font-primary);

  h2 {
    text-align: center;
    padding-bottom: 2rem;

    @media screen and (max-width: 600px) {
      text-align: left;
    }
  }

  .faq-row-wrapper {
    .faq-body {
      .faq-row {
        .row-title {
          @media screen and (max-width: 600px) {
            padding: 1rem 0rem !important;
          }
        }

        .row-content {
          .row-content-text {
            @media screen and (max-width: 600px) {
              padding: 1rem 0rem !important;
            }
          }
        }
      }
    }
  }
}
