@import url("https://fonts.googleapis.com/css2?family=PT+Serif&family=Poppins:wght@300;400;700;800&display=swap");

:root {
  --font-secondary: "PT Serif", serif;
  --font-primary: "Poppins";

  --primary-color: #edf2f8;
  --secondary-color: #313bac;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --white-color: #ffffff;
  --orange-color: #fd841f;
  --lightOrange-color: #f94a29;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
