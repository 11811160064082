.app__trackSection-container {
  margin: 2rem 1rem;

  .text {
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    font-family: var(--font-primary);
    text-transform: capitalize;
    margin-bottom: 2rem;

    @media screen and (max-width: 900px) {
      text-align: left;
    }

    span {
      font-size: 1.2rem;
      font-weight: 600;
      border-bottom: 2px solid var(--lightOrange-color);

      @media screen and (max-width: 900px) {
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }
}

.app__trackingSection-tracking {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0rem 3rem;
  flex-wrap: wrap;
  gap: 2rem;

  img {
    width: 40%;
    height: 100%;
    transition: all 0.5s ease-in-out;
    object-fit: cover;

    @media screen and (max-width: 900px) {
      width: 100%;
    }
  }

  .rhs {
    background-color: var(--orange-color);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
    height: 100%;
    padding: 2rem;
    -moz-transform: skew(-5deg, 0deg);
    -webkit-transform: skew(-5deg, 0deg);
    -o-transform: skew(-5deg, 0deg);
    -ms-transform: skew(-5deg, 0deg);
    transform: skew(-5deg, 0deg);

    h2 {
      font-family: var(--font-primary);
      font-size: 2.5rem;
      text-align: left;
      padding-bottom: 2rem;
    }

    p {
      font-family: var(--font-primary);
      padding-bottom: 1rem;
      font-size: 1rem;
      font-style: italic;
    }

    input {
      padding: 0 60px;
      height: 60px;
      width: 100%;
      outline: none;
      border: none;

      &::placeholder {
        font-family: var(--font-primary);
        font-size: 0.9rem;
      }
    }

    button {
      margin-top: 1.5rem;
      padding: 1rem 2rem;
      color: #fff;
      background: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
    }

    @media screen and (max-width: 900px) {
      transform: skew(0deg);
      flex: 1;
      width: 100%;
    }
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    gap: 0rem;
    margin: 0;
  }
}

.app__tracking-hero {
  display: flex;
  flex-direction: row;
  margin: 4rem 1rem;
  gap: 2rem;

  .app__tracking-hero-lhs {
    background: transparent;
    padding: 1rem 2rem;
    h2 {
      padding-top: 2rem;
      padding-bottom: 2rem;
      font-size: 3rem;
      font-family: var(--font-primary);
      font-weight: 700;
      line-height: 1.2;

      @media screen and (max-width: 900px) {
        font-size: 2rem;
        font-weight: 700;
        line-height: 1.2;
        text-align: left;
        margin: 0;
      }
    }

    p {
      padding-bottom: 2rem;
      font-size: 1rem;
      font-family: var(--font-primary);
      font-weight: 400;
      line-height: 1.5;
      color: var(--black-color);
      @media screen and (max-width: 900px) {
        text-align: left;
        margin: 1rem;
      }
    }

    .btn-track {
      padding: 1rem 2rem;
      color: var(--white-color);
      background-color: var(--lightOrange-color);
      font-family: var(--font-primary);
      font-size: 1rem;
      text-transform: capitalize;
      border: none;
      outline: none;
      border-radius: 2px;

      &:hover {
        box-shadow: 7px 7px 7px var(--lightGray-color);
        transition: all 0.3s ease-in-out;
      }
    }

    @media screen and (max-width: 900px) {
      margin-bottom: 1.5rem;
    }
  }

  img {
    width: 700px;

    @media screen and (max-width: 900px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    margin-top: 5rem;
  }
}
