.app__header {
  width: 100%;
  height: 80vh;
  background: url("../../assets/slide01.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 5rem;

  div {
    position: relative;
    color: var(--white-color);
    font-family: var(--font-primary);
    top: 38%;
    padding: 0 4rem;
    width: 50%;

    h2 {
      font-size: 5rem;
      font-weight: 700;

      @media screen and (max-width: 900px) {
        font-size: 2rem;
        font-weight: 700;
      }
    }

    p {
      font-size: 1.2rem;
      font-weight: 500;
      color: var(--lightGray-color);

      @media screen and (max-width: 900px) {
        font-size: 1rem;
      }
    }

    @media screen and (max-width: 900px) {
      top: 50%;
      padding: 1rem;
      width: 100%;
    }
  }

  @media screen and (max-width: 900px) {
    height: 60vh;
  }
}
