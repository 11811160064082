.app__testimonial {
  margin: 4rem;
  min-height: 0;
  min-width: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  gap: 2rem;

  .slick-slider {
    overflow: hidden !important;
    max-width: 600px !important;
  }

  .lhs {
    overflow: hidden;
    padding: 2rem 4rem;
    background-color: var(--lightOrange-color);
    color: var(--white-color);
    width: 300px;
    height: 400px;
    max-width: 600px;
    flex: 1;
    font-family: var(--font-primary);

    svg {
      font-size: 3.5rem;
      background: #000;
      border-radius: 100%;
      padding: 15px 6px;

      @media screen and (max-width: 900px) {
        font-size: 2.5rem;
        padding: 7.5px 3px;
      }
    }

    p {
      padding: 2rem 0;
    }

    span {
      font-style: italic;
      font-size: 0.9rem;
    }

    @media screen and (max-width: 900px) {
      width: 100%;
      height: 100%;
      padding: 2rem 1rem;
    }
  }

  .rhs {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;

    h1 {
      font-family: var(--font-primary);

      @media screen and (max-width: 900px) {
        margin-left: 1.5rem;
        font-size: 1.5rem;
      }
    }

    div {
      width: 150px;
      margin: 1.5rem;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 2px solid var(--black-color);
        padding: 6px 10px;
        filter: grayscale(1);

        &:hover {
          img {
            filter: grayscale(0);
          }
        }
      }

      @media screen and (min-width: 2000px) {
        width: 210px;
        margin: 2rem;
      }

      @media screen and (max-width: 450px) {
        width: 90%;
        margin: 1rem;
      }
    }

    @media screen and (max-width: 800px) {
      width: 90%;
      margin: 1rem;
    }
  }

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    margin: 0;
    align-items: unset;
  }
}
