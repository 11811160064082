.app__serviceSection {
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .app__serviceSection-lhs {
    margin-left: 2rem;

    h4 {
      font-family: var(--font-primary);
      font-size: 1rem;
      color: var(--gray-color);
      padding-bottom: 1rem;
    }

    h2 {
      font-family: var(--font-primary);
      font-size: 3rem;
      color: var(--black-color);
      padding-bottom: 1.5rem;
      line-height: 1;

      @media screen and (max-width: 900px) {
        font-size: 2rem;
      }
    }

    .service-list-container {
      margin-left: 2rem;
      p {
        padding: 1.5rem 1.5rem;
        width: 75%;
        background: var(--black-color);
        margin-bottom: 2rem;
        color: var(--white-color);
        font-family: var(--font-primary);
        font-size: 1rem;
        font-weight: 500;

        &:hover {
          color: var(--lightOrange-color);
        }

        @media screen and (max-width: 900px) {
          padding: 1rem 1rem;
          width: 80%;
        }
      }
    }

    button {
      border: none;
      outline: none;
      background: transparent;
      border-bottom: 4px solid var(--lightGray-color);
      color: var(--black-color);
      font-family: var(--font-primary);
      font-size: 1.2rem;
      font-weight: 700;
      margin-left: 1rem;

      &:hover {
        border-bottom: 4px solid var(--lightOrange-color);
        color: var(--lightOrange-color);
      }

      @media screen and (max-width: 900px) {
        font-size: 1rem;
        font-weight: 600;
      }
    }
  }

  .app__serviceSection-rhs {
    img {
      height: auto;
      width: 100%;

      @media screen and (max-width: 900px) {
        // height: 100%;
        width: 100%;
        object-fit: contain;
        overflow: hidden;
      }
    }
  }

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
