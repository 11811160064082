.app__contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--font-primary);
  color: var(--black-color);
  margin: 2rem 4rem;
  padding: 2rem 4rem;

  img {
  }

  h1 {
    font-size: 4rem;
    text-align: center;

    @media screen and (max-width: 900px) {
      text-align: left;
      font-size: 2rem;
    }
  }

  p {
    font-size: 1.2rem;
    text-align: center;

    @media screen and (max-width: 900px) {
      text-align: left;
      font-style: 1rem;
    }
  }

  @media screen and (max-width: 900px) {
    margin: 0.9rem 1.9rem;
    padding: 1rem 1.5rem;
  }
}

.app__contact-address {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  margin-top: 2rem;

  div {
    padding: 3rem 3rem;
    border: 1px solid black;
    font-family: var(--font-primary);

    h4 {
      padding-bottom: 1rem;
    }
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
}

.btn-phone {
  margin-top: 1rem;
  margin-left: 12rem;
  padding: 1rem 1.5rem;
  outline: none;
  border: none;
  font-family: var(--font-primary);
  font-size: 1rem;
  background: #4298bd;
  color: #fff;
  margin-bottom: 2rem;
  border-radius: 8px;

  svg {
    display: inline;
    font-style: 2rem;
  }

  @media screen and (max-width: 900px) {
    margin-left: 2rem;
  }
}

.app__contact-form {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f9f7ef;

  .form-text {
    font-family: var(--font-primary);
    padding: 2rem 2rem;

    p {
      color: var(--gray-color);
      font-size: 1rem;
    }

    h2 {
      font-size: 2.5rem;
      color: var(--black-color);
    }
  }

  .app__footer-form {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1rem 2rem;

    div {
      width: 100%;
      margin: 0.75rem 0;
      cursor: pointer;
      border-radius: 10px;
      background-color: var(--lightGray-color);
      transition: all 0.3s ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;

      input,
      textarea {
        width: 100%;
        padding: 0.95rem;
        border: none;
        border-radius: 7px;
        background-color: var(--lightGray-color);
        font-family: var(--font-primary);
        color: var(--black-color);
        outline: none;
      }

      textarea {
        height: 170px;
      }

      &:hover {
        box-shadow: 0 0 25px var(--lightGray-color);
      }
    }

    .btn-submit {
      padding: 1rem 2rem;
      border-radius: 10px;
      border: none;
      background-color: var(--lightOrange-color);
      font-weight: 500;
      color: var(--white-color);
      outline: none;
      margin: 2rem 0 0 0;
      font-family: var(--font-primary);
      cursor: pointer;

      &:hover {
        border: 1px solid var(--lightOrange-color);
        background: transparent;
        color: var(--black-color);
      }
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 1rem 0;
    }
  }
}
