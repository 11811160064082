.app__counter {
  width: 100%;
  margin-top: 7rem;
  height: auto;

  .app__counter-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    div {
      border: 1px dotted var(--lightOrange-color);
      width: 230px;
      height: 200px;
      padding: 0 5px;
      margin-bottom: 1rem;
      outline: none;
      background: var(---white-color);
      box-shadow: 5px 5px 5px var(--lightGray-color);

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:nth-child(2) {
        position: relative;
        top: 6rem;
        @media screen and (max-width: 900px) {
          top: 0;
        }
      }
      &:nth-child(3) {
        position: relative;
        bottom: 4rem;
        @media screen and (max-width: 900px) {
          bottom: 0;
        }
      }
      &:nth-child(4) {
        position: relative;
        top: 8rem;
        @media screen and (max-width: 900px) {
          top: 0;
        }
      }
      &:nth-child(5) {
        position: relative;
        top: 3rem;

        @media screen and (max-width: 900px) {
          top: 0;
        }
      }

      h2 {
        font-family: var(--font-primary);
        font-size: 4rem;
        letter-spacing: 4px;
      }

      p {
        padding-top: 1rem;
        font-family: var(--font-primary);
        color: var(--gray-color);
        font-size: 1rem;
        font-weight: 400;
      }

      @media screen and (max-width: 900px) {
        width: 85%;
      }
    }

    @media screen and (max-width: 900px) {
      flex-direction: column;
      margin: 0rem 3rem;
    }
  }
}
