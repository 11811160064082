.tracking-info-container {
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #000;
  padding: 20px;
  padding-top: 7rem;
  padding-bottom: 2rem;
  font-family: var(--font-primary);

  @media only screen and (max-width: 600px) {
    padding: 10px;
  }
}

.error-div {
  height: 50vh;
  padding-top: 7rem;
  font-family: var(--font-primary);

  h2 {
    text-align: center;
    color: #ff0000;
  }
}

.tracking-info-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 7rem;
  }
}

.company-logo {
  width: 100px;
  height: 50px;
  background-color: #00092c;
  border-radius: 5px;

  @media only screen and (max-width: 600px) {
    margin-bottom: 10px;
  }
}

.tracking-status {
  display: flex;
  align-items: center;

  @media only screen and (max-width: 600px) {
    margin-top: 10px;
  }
}

.status-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 10px;
  animation: blink 1s infinite;

  @media only screen and (max-width: 600px) {
    margin-right: 5px;
    width: 11px;
    height: 11px;
  }
}

.red-blinking {
  background-color: #ff0000;
}

.green-blinking {
  background-color: #2ecc40;
}

.status {
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;

  @media only screen and (max-width: 600px) {
    font-size: 1rem;
  }
}

.tracking-id {
  font-size: 1.2rem;
  font-weight: bold;

  @media only screen and (max-width: 600px) {
    font-size: 1rem;
  }
}

.tracking-info-body {
  margin-top: 40px;

  @media only screen and (max-width: 600px) {
    margin-top: 20px;
  }
}

.tracking-info-section {
  margin-bottom: 40px;

  h3 {
    margin-bottom: 20px;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;

    @media only screen and (max-width: 600px) {
      font-size: 0.8rem;
    }
  }

  .contact-info {
    width: 70%;
    height: 50%;
    border-radius: 5px;
    border: 1px solid transparent;
    box-shadow: 2px 2px 2px #ccc;
  }

  .tracking-info-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    gap: 3rem;
  }

  .tracking-info-label {
    flex-basis: 30%;
    font-weight: bold;
    color: #ccc;

    @media only screen and (max-width: 600px) {
      flex-basis: 40%;
      font-size: 0.8rem;
    }
  }

  .tracking-info-value {
    flex-basis: 70%;

    @media only screen and (max-width: 600px) {
      flex-basis: 60%;
      font-size: 0.8rem;
    }
  }
}

button {
  border: none;
  padding: 1rem 1.5rem;
  outline: none;
  color: #fff;
  background: #000;
  cursor: pointer;
  font-family: var(--font-primary);
  font-size: 0.8rem;
}

@keyframes blink {
  50% {
    opacity: 0.1;
  }
}
