.app__footer {
  background: #00092c;
  height: 55vh;
  color: white;
  font-family: var(--font-primary);
  list-style: none;

  .app__container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 5rem;
    padding-bottom: 2rem;

    .first_container {
      font-size: 0.9rem;

      img {
        width: 200px;

        @media screen and (max-width: 900px) {
          width: 100px;
        }
      }
      @media screen and (max-width: 900px) {
        padding: 1rem 2rem;
      }
    }

    h5 {
      font-size: 0.9rem;
      font-weight: 500;
      border-bottom: 1px solid var(--lightOrange-color);
    }

    ul {
      list-style: none;
      font-size: 0.9rem;
      padding-top: 1rem;
      font-weight: 400;

      li {
        list-style: none;
      }
    }

    @media screen and (max-width: 900px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 2rem;
      justify-content: space-evenly;
    }
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    color: var(--lightOrange-color);
    font-style: italic;
  }

  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      color: var(--lightOrange-color);
    }

    &:active {
      color: #fff;
    }
  }

  @media screen and (max-width: 900px) {
    height: fit-content;
    width: 100%;
  }
}
