.app__service-firstPart {
  margin-top: 2rem;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
    }

    h2 {
      text-align: center;
      font-size: 3rem;
      font-family: var(--font-primary);

      @media screen and (max-width: 900px) {
        font-size: 2rem;
        text-align: left;
        padding: 10px;
      }
    }
  }

  .text-flex {
    margin: 2rem 4rem;
    display: flex;
    justify-content: space-between;
    gap: 3rem;

    p {
      font-family: var(--font-primary);
      font-size: 1.1rem;
      line-height: 1.5;
      color: var(--black-color);

      &:nth-child(1) {
        flex: 1;
      }

      &:nth-child(2) {
        flex: 1;
      }
    }

    @media screen and (max-width: 900px) {
      display: flex;
      flex-direction: column;
      margin: 1rem 1.5rem;
    }
  }
}

.level-one {
  display: flex;
  justify-content: space-between;

  .text {
    flex: 1;
    background-color: rgb(246, 246, 229);
    padding: 5rem 5rem;
    font-family: var(--font-primary);
    color: var(--black-color);

    h2 {
      font-size: 3rem;
      padding-bottom: 2rem;
    }

    p {
      font-size: 1rem;
      line-height: 1.5;
      padding-bottom: 4rem;

      @media screen and (max-width: 900px) {
        padding-bottom: 2rem;
      }
    }

    button {
      background: var(--lightOrange-color);
      border: none;
      outline: none;
      padding: 1rem 2rem;
      color: var(--white-color);
      font-family: var(--font-primary);
      font-size: 1rem;
      text-transform: uppercase;
    }

    @media screen and (max-width: 900px) {
      padding: 1rem 1rem;
    }
  }

  img {
    width: 100%;
    vertical-align: middle;
    flex: 1;

    @media screen and (max-width: 900px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
  }
}

.level-two {
  display: flex;
  justify-content: space-between;

  img {
    width: 100%;
    vertical-align: middle;
    flex: 1.435;
  }

  .txt-cont {
    flex: 1;
    padding: 2rem 2rem;
    font-family: var(--font-primary);

    h2 {
      padding-top: 2rem;
      font-size: 3rem;
      padding-bottom: 2rem;

      @media screen and (max-width: 900px) {
        font-size: 2rem;
        padding-bottom: 1rem;
      }
    }

    p {
      color: var(--black-color);
      padding-bottom: 1rem;
      font-size: 1rem;
    }

    ul {
      padding-left: 3rem;

      li {
        list-style: square;
      }
    }
  }

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }
}

.level-three {
  display: flex;
  justify-content: space-between;

  .txt-cont-2 {
    flex: 1.175;
    padding: 3rem 2rem;
    font-family: var(--font-primary);
    background-color: var(--black-color);
    color: #fff;

    h2 {
      font-size: 3rem;
      padding-bottom: 3rem;

      @media screen and (max-width: 900px) {
        font-size: 2rem;
        padding-bottom: 1rem;
      }
    }

    p {
      font-size: 1rem;
      line-height: 1.5;
    }
  }

  img {
    width: 100%;
    vertical-align: middle;
    flex: 1;

    @media screen and (max-width: 900px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }
}
