.app__homeCards {
  margin: 5rem 0rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  .app__homeCards-card {
    border: 0.5px solid var(--lightOrange-color);
    outline: none;
    border-radius: 7px;
    padding: 2rem 2rem;
    width: 300px;
    height: 360px;
    background: transparent;
    box-shadow: 4px 4px 4px var(--lightGray-color);

    svg {
      font-size: 3rem;
      color: var(--orange-color);
      margin-bottom: 2rem;
    }

    h2 {
      font-family: var(--font-primary);
      font-size: 2rem;
      color: var(--black-color);
      margin-bottom: 2rem;
    }

    p {
      font-family: var(--font-primary);
      font-size: 0.9rem;
      line-height: 1.5;
      color: var(--gray-color);
      margin-bottom: 2rem;
    }

    button {
      border: none;
      outline: none;
      font-size: 1rem;
      font-family: var(--font-primary);
      border-bottom: 2px solid var(--gray-color);
      cursor: pointer;
      background: transparent;

      &:hover {
        border-bottom: 2px solid var(--lightOrange-color);
      }
    }

    &:hover {
      box-shadow: 12px 12px 12px var(--lightGray-color);
      transition: all 0.3s ease-in;
    }

    @media screen and (max-width: 900px) {
      margin: 2rem 3rem;
      width: 70%;
    }
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
