.app__gallery-container {
  margin: 5rem 0rem;
  background: var(--lightGray-color);
  padding: 3rem 1rem;

  .gallery-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      width: 100px;
      height: 30px;
    }

    h2 {
      font-family: var(--font-primary);
      font-size: 2rem;
      font-weight: 600;
      color: var(--lightOrange-color);
      padding-bottom: 0.5rem;
    }

    p {
      font-family: var(--font-primary);
      font-size: 1rem;
      font-weight: 400;
      color: var(--black-color);
      padding-bottom: 1rem;
    }
  }

  .app__image-gallery-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 4px;
    width: 90%;
    margin-inline: auto;

    .gallery-items {
      flex: 40%;
      max-width: 50%;
      padding: 0px 5px;

      @media screen and (max-width: 900px) {
        max-width: 100%;
      }

      .gallery-cont {
        position: relative;
        z-index: 1;

        img {
          margin-top: 8px;
          vertical-align: middle;
          width: 100%;
          object-fit: cover;
          object-position: center;
          z-index: 2;
          opacity: 1;

          @media screen and (max-width: 600px) {
            width: 400px;
            height: 300px;
            object-fit: cover;
          }
        }

        .text-overlay {
          position: absolute;
          z-index: 3;
          color: var(--white-color);
          padding: 5px 15px;
          bottom: 15px;

          p {
            font-family: var(--font-primary);
            font-size: 0.9rem;
            padding-bottom: 9px;
            font-weight: 500;
          }

          h2 {
            font-family: var(--font-primary);
            font-size: 1.5rem;
            padding-bottom: 10px;
          }

          button {
            border: none;
            outline: none;
            font-family: var(--font-primary);
            font-size: 1rem;
            border-bottom: 3px solid var(--lightOrange-color);
            color: var(--white-color);
            background: transparent;
            font-weight: 600;
          }
        }
      }
    }

    @media screen and (max-width: 900px) {
      display: flex;
      flex-direction: column;
      padding-left: 2rem;
      margin: 0;
      object-fit: contain;
      overflow: hidden;
    }
  }
}
