.app__logistic {
  margin-bottom: 3rem;

  h1 {
    text-align: center;
    margin-bottom: 1rem;
    font-family: var(--font-primary);
  }

  .logistic__container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 10px;
    padding: 0rem 2rem;

    .text-content {
      width: 50%;
      font-family: var(--font-primary);
      color: var(--gray-color);
      padding: 3rem 1rem;

      p {
        font-size: 1.2rem;
        line-height: 1.5;
      }

      @media screen and (max-width: 900px) {
        width: 100%;
      }
    }

    img {
      width: 60%;
      height: auto;
      border-radius: 3px;

      @media screen and (max-width: 900px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }
  }
}

.logistic-contact {
  background: var(--lightOrange-color);
  width: 70%;
  margin: 3rem 1rem;
  padding: 4rem 3rem;
  position: relative;
  left: 15%;
  color: var(--white-color);
  font-family: var(--font-primary);
  border-radius: 5px;
  box-shadow: 6px 6px 6px var(--lightGray-color);

  h2 {
    font-size: 3rem;
    padding-bottom: 1.5rem;
  }

  div {
    display: flex;
    justify-content: space-between;

    p {
      font-style: 1.2rem;

      @media screen and (max-width: 900px) {
        padding-bottom: 2rem;
      }
    }

    button {
      padding: 1rem 2rem;
      border: none;
      outline: none;
      background: var(--black-color);
      font-family: var(--font-primary);
      font-size: 1rem;
      color: white;
      text-transform: uppercase;
    }

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 900px) {
    width: 90%;
    left: 0;
  }
}
