.app__banner {
  margin-top: 3rem;
  width: 100%;
  background: url("../../assets/section-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #000;
  height: 100%;

  .banner_content {
    padding: 100px 0;
    background-size: cover;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: var(--white-color);
    font-family: var(--font-primary);

    h2 {
      font-size: 3rem;
      padding-bottom: 1rem;

      span {
        color: var(--lightOrange-color);
      }

      @media screen and (max-width: 900px) {
        font-size: 2rem;
      }
    }

    p {
      font-size: 1rem;
      padding-bottom: 2rem;
      line-height: 1.5;

      @media screen and (max-width: 900px) {
        text-align: center;
      }
    }

    .btn-div {
      display: flex;
      gap: 2rem;
      justify-content: center;

      .banner-btn {
        margin-right: 2rem;
        padding: 1rem 3rem;
        font-family: var(--font-primary);
        font-size: 1rem;

        &:nth-child(1) {
          outline: none;
          border: none;
          background: var(--lightOrange-color);
          color: var(--white-color);

          &:hover {
            border: 2px solid var(--lightOrange-color);
            background: transparent;
          }

          @media screen and (max-width: 900px) {
            padding-left: 3rem;
            padding: 1rem 2rem;
          }
        }
        &:nth-child(2) {
          outline: none;
          border: 2px solid var(--lightOrange-color);
          background: transparent;
          color: var(--white-color);

          &:hover {
            border: none;
            background: var(--lightOrange-color);
          }

          @media screen and (max-width: 900px) {
            display: none;
          }
        }
      }
      @media screen and (max-width: 600px) {
        display: none;
      }
    }

    @media screen and (max-width: 900px) {
      padding: 100px 10px;
    }
  }

  @media screen and (max-width: 900px) {
    margin-bottom: 2rem;
  }
}
