.app__slider-container {
  position: relative;
  width: 100%;
  height: 95vh;
  margin: 0;
  padding: 0;
  background-color: var(--black-color);
  outline: none;

  @media screen and (max-width: 900px) {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-size: cover !important;

    @media screen and (max-width: 900px) {
      width: 200%;
      height: 100%;
    }
  }

  .app__slider-textOverlay {
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translate(-50%, -50%);
    color: var(--white-color);
    text-align: left;
    font-family: var(--font-primary);
    margin-left: 2rem;
    padding-left: 3rem;

    @media screen and (max-width: 900px) {
      margin-left: 0rem;
      top: 50%;
      left: 40%;
      padding-left: 0rem;
      padding-top: 4.5rem;
    }

    h2 {
      font-size: 3.2rem;
      font-weight: 800;
      padding-bottom: 1rem;
      font-family: var(--font-primary);
      text-transform: capitalize;

      @media screen and (max-width: 900px) {
        font-size: 2rem;
        font-weight: 700;
      }
    }

    p {
      font-size: 1.1rem;
      font-weight: 600;
      padding-bottom: 1rem;
      text-transform: capitalize;

      @media screen and (max-width: 900px) {
        font-size: 0.9rem;
        font-weight: 500;
      }
    }

    button {
      padding: 1rem 3rem;
      background: var(--lightOrange-color);
      border-radius: 3px;
      outline: none;
      border: none;
      font-size: 1rem;
      font-family: var(--font-primary);
      text-transform: uppercase;
      color: var(--white-color);

      &:hover {
        background: #f54825;
        color: var(--black-color);
        box-shadow: 1px 1px 1px rgba(225, 225, 225, 0.95);
        transition: all 0.3s ease-in-out;
      }

      @media screen and (max-width: 900px) {
        padding: 0.8rem 1.8rem;
        font-size: 0.7rem;
      }
    }
  }
}
